





















import { Component, Prop, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import DisplayCurrencyTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';
import { Inject } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import { COUNTRIES } from '@/modules/common/constants/Countries';
import CarsStore from '../store/cars.store';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';

@Component({
    components: {
        DateDocumentFilter,
        CurrencySwitcher,
        DisplayCurrencyTooltip,
    },
})
export default class CarHeader extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    readonly carsStoreState: CarsStore = this.storeFacade.getState('CarsStore');

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    private showTooltip!: boolean;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    private showCurrencySwitcher!: boolean;

    infoTooltip: boolean = false;

    get mainCurrency() {
        if (this.carsStoreState.settings.pickUpCityCode) {
            const countryName = this.carsFiltersService.getCountryByPickUpLocationId(this.carsStoreState.settings.pickUpCityCode);
            const country = COUNTRIES.country.filter(x => x.countryName.toLowerCase() === countryName?.toLowerCase());
            if (country && country.length) {
                return country[0].currencyCode;
            }
        }
        return '';
    }
}
